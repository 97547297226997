import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import Component from "@reactions/component";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Pane, Dialog, Spinner, SideSheet, Position } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Row, Col, Card, ListGroup, Button } from 'react-bootstrap';
import MaterialDatatable from "material-datatable";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Logo from '../../assets/img/printlogo.jpg';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Link } from 'react-router-dom';
import Login from '../common/login';
import Barcode from 'react-barcode';


const cookies = new Cookies();
const columns = [
    { field: "print", name: "اعادة طباعة", options: { width: 100, filter: false, sort: false, search: false } },
    { field: "createdAt", name: "تاريخ الاضافة", options: { width: 100, filter: false, sort: false, } },

    { field: "notes", name: "ملاحظات", options: { filter: true, sort: false, search: false } },
    { field: "battery", name: "نسبة البطارية", options: { filter: false, sort: false, search: false } },
    { field: "sn", name: "S/N", options: { filter: false, search: false } },
    { field: "name", name: "الجهاز", options: { filter: false, search: false } }

    ,

    { field: "id", name: "#", options: { filter: false, } },
];

const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    sort: false,
    print: false,
    filter: false,
    search: false,
    download: false,
    textLabels: {
        body: {
            noMatch: " لم يتم العثور على سجلات مطابقة",
            toolTip: "فرز",
        },
        pagination: {
            next: "الصفحة التالية",
            previous: "الصفحة السابقة",
            rowsPerPage: "عدد الصفوف",
            displayRows: "من",
        },
        toolbar: {
            search: "بحث",
            downloadCsv: "تنزيل",
            print: "Print",
            viewColumns: " التحكم بالاعمدة",
            filterTable: "فلتر",
        },
        filter: {
            all: "الكل",
            title: "فلتر",
            reset: "إعادة تعيين",
        },
        viewColumns: {
            title: "عرض الأعمدة",
            titleAria: "إظهار / إخفاء أعمدة الجدول",
        },

    },

}


class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nameCate: '',
            image: [],
            Category: [],
            spin: false,
            sn: '',
            notes: '',
            battery: "",
            name: "",
            id: "",

        }
    }

    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'right',
                },
            },
            MuiTableCell: {
                root: {
                    textAlign: 'right',

                },
                head: {
                    // backgroundColor: "#FF0000",
                }
            },

        }
    })
    componentDidMount() {


        const urlParams = new URLSearchParams(window.location.search);



        let Getid = urlParams.get('id')
        var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("tokenUser") };
        axios.get(host + `users/sana/orders/phone/${Getid}`, { headers: header })
            .then(res => {
                // console.log(res.data.data.items);
                let out = "لم يتم التحديد"
                if (res.data.phone.item_out) {
                    out = res.data.phone.item_out
                }
                let type = "صيانة"

                if (res.data.phone.type == 1) {
                    type = "رواجع"
                }
                this.setState({
                    sn: res.data.phone.uuid,
                    battery: res.data.phone.battery,
                    name: res.data.phone.item_out.name,
                    id: res.data.phone.id,
                    spin: true
                });
            })
            .catch(error => { console.log(error.response) })





    }
    EditCate(id) {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        let formData = new FormData();
        formData.append("name", this.state.nameCate);
        formData.append("is_count", 0);
        formData.append("file", this.state.image[0]);
        axios({ url: host + `dashbord/category/${id}`, method: "PUT", data: formData, headers: header })
            .then(response => {
                this.setState({ image: '' })
                this.componentDidMount()
                toast('تم التعديل بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            })
            .catch(error => { console.log(error.response.data.message) })
    }
    DeleteCate(id) {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.delete(host + `dashbord/Devices/Device/${1}`, { headers: header })
            .then(response => {
                toast('تم الحذف بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                this.componentDidMount();
            })
            .catch(error => { console.log(error.response.data.message) })
    }
    print() {

        var printContents = document.getElementById('divcontents').innerHTML;
        var originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
        window.location.reload();


    }
    AddCate() {
        let name = document.getElementById('name').value;

        let sn = document.getElementById('sn').value;
        let battery = document.getElementById('battery').value;

        let notes = document.getElementById('notes').value;

        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        let formData = new FormData();
        formData.append("name", name);
        formData.append("sn", sn);
        formData.append("battery", battery);
        formData.append("notes", notes);

        axios({ url: host + `dashbord/Devices/new`, method: "POST", data: formData, headers: header })
            .then(response => {
                this.componentDidMount();
                console.log(response.data.devices.id);

                // this.componentDidMount();
                toast('تم اضافة الجهاز بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            })
            .catch(error => { console.log(error.response.data.message) })
    }
    render() {
        if (cookies.get("token")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        if (this.state.spin) {
                            return (
                                <div >
                                    <div id='navDashContiner'>


                                    </div>

                                    <Card style={{ width: '100%', alignItems: 'start' }} id="divcontents">

                                        <div id='labelCOntiner'>
                                            <div style={{ width: '20%' }} id='labelCOntiner1' >

                                                <Barcode format={"CODE128"} textAlign={"center"} height={70} displayValue={false} fontSize={26} value={this.state.id} />

                                            </div>
                                
                                            <div style={{ fontSize: "14px" }}># {this.state.id}</div>
                                            <div style={{ fontSize: "14px" }}>{this.state.name}</div>
                                        </div>
                                        <iframe id="ifmcontentstoprint" style={{ height: "0px", width: " 0px", position: " absolute" }}></iframe>

                                    </Card>
                                    <hr />
                                    <div>
                                        <center>
                                            <Button style={{margin:20}} onClick={() => {
                                                this.print()
                                            }}>طباعة
                                            </Button>

                                            <Button style={{margin:20}} onClick={() => {
                                                window.location.href = `/sanaCheck`;
                                            }}>رجوع
                                            </Button>
                                        </center>
                                       
                                        
                                    </div>


                                    <ToastContainer
                                        position="bottom-left"
                                        autoClose={10000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnVisibilityChange
                                        draggable
                                        pauseOnHover
                                    />
                                </div>
                            )
                        }
                        else {
                            return (
                                <div style={{ width: '100%', height: '100vh ', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Spinner />
                                </div>
                            )
                        }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Category;