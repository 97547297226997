import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import { Row, Col, Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Component from "@reactions/component";
import { SideSheet, Position, Pane, Dialog, SelectField } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import { Checkbox, FormControlLabel } from '@mui/material/';
import Info from '@material-ui/icons/Info';
import { Link } from 'react-router-dom';
import CategoryIcon from '@material-ui/icons/Category';
import SyncDisabledRounded from '@material-ui/icons/SyncDisabledRounded';



import FileUpload from '@material-ui/icons/SyncRounded';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
// import Lottie from 'lottie-react-web';
// import animation from '../../assets/json/clean.json';
import 'react-toastify/dist/ReactToastify.css';
import MaterialDatatable from "material-datatable";
import { createMuiTheme, MuiThemeProvider, } from '@material-ui/core/styles';
import qs from 'qs';
import * as moment from "moment-timezone";
import Login from '../common/login';
const columns = [

    { field: "more", name: "المزيد", options: { width: 60, filter: true, sort: false, } },
    { field: "is_send", name: "ارسال", options: { filter: true, sort: false, } },

    { field: "items", name: "المواد", options: { width: 200, filter: true, sort: false, } },
    { field: "date", name: "التاريخ ", options: { filter: true, sort: false, } },

    { field: "count", name: "العدد ", options: { filter: true, sort: false, } },
    { field: "name", name: "الشركة ", options: { filter: true, sort: true, } },
    { field: "id", name: "#", options: { width: 200, filter: true, sort: false, } },
];
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    sort: false,
    print: false,
    filter: false,
    download: true,
    textLabels: {
        body: {
            noMatch: " لم يتم العثور على سجلات مطابقة",
            toolTip: "فرز",
        },
        pagination: {
            next: "الصفحة التالية",
            previous: "الصفحة السابقة",
            rowsPerPage: "عدد الصفوف",
            displayRows: "من",
        },
        toolbar: {
            search: "بحث",
            downloadCsv: "تنزيل",
            print: "Print",
            viewColumns: " التحكم بالاعمدة",
            filterTable: "فلتر",
        },
        filter: {
            all: "الكل",
            title: "فلتر",
            reset: "إعادة تعيين",
        },
        viewColumns: {
            title: "عرض الأعمدة",
            titleAria: "إظهار / إخفاء أعمدة الجدول",
        },

    }
}

const cookies = new Cookies();

class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: [],
            from: new Date(),
            to: new Date(),
            spin: false,
            serch: false,
            total: '',
            shiper: [],
            company: "",
        }
    }
    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'right',
                },

            },
            MuiTableCell: {
                root: {
                    textAlign: 'right',

                },
                head: {
                    // backgroundColor: "#FF0000",
                }
            },

        }
    })

    componentDidMount() {
        var header = { "Content-Type": "application/json", token: cookies.get("token") };

        axios.get(host + `dashbord/shiper/all/`, { headers: header })
            .then(res => {

                this.setState({ shiper: res.data.shiper })

            })
            .catch(error => {
                console.log(error.response)
            });
        axios.get(host + `users/rejctedList/all/`)
            .then(res => {
                let arr = [];

                for (let i = 0; i < res.data.orders.length; i++) {

                    let obj = {
                        id: res.data.orders[i].id,
                        name: res.data.orders[i].name,


                        date: moment(res.data.orders[i].createdAt).tz('Asia/baghdad').format('YYYY/MM/DD LT'),
                        count: res.data.orders[i].count,
                        items: <Link to={`/RejectListItems?id=${res.data.orders[i].id}`}><CategoryIcon style={{ color: '#ffc107' }} /></Link>,
                        is_send: "",
                        more: <Link to={`/list?id=${res.data.orders[i].id}`}><Info /></Link>,
                        send: <Component initialState={{ isShown: false }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        title="ارسال القائمة"
                                        intent="success"
                                        onCloseComplete={() => setState({ isShown: false })}
                                        confirmLabel="ارسال"
                                        cancelLabel="الغاء"
                                        onConfirm={() => {
                                            setState({ isShown: false })
                                            // this.update_list(items[i].id)

                                            // this.componentDidMount()
                                        }}
                                    >
                                        <span id='msgDelete'> هل انت متأكد من ارسال القائمة رقم {res.data.orders[i].id}</span>
                                    </Dialog>
                                    <SyncDisabledRounded id="sendBtn" style={{ color: '#085119', cursor: 'pointer' }} onClick={() => { setState({ isShown: true }) }} />

                                </Pane>
                            )}
                        </Component>

                    };

                    if (res.data.orders[i].is_send) {
                        obj.is_send = <FileUpload style={{ color: '#28a745', cursor: 'pointer' }} />
                    } else {
                        obj.is_send = <FileUpload style={{ color: '#085119', cursor: 'pointer' }} />

                    }

                    arr.push(obj);
                }

                this.setState({
                    orders: arr, spin: false
                });
            })
            .catch(error => {
                console.log(error.response)
            });
    }
    DeleteCate(id) {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.delete(host + `dashbord/spending/${id}?`, { headers: header })
            .then(response => {
                toast('تم الحذف بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                this.Orders();
            })
            .catch(error => { console.log(error) })
    }


    handleChangeFrom = date => {
        console.log(date);

        this.setState({
            from: date
        });
    };
    handleChangeTo = date => {
        this.setState({
            to: date
        });
    };
    render() {
        if (cookies.get("token")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        // if (this.state.spin) {
                        return (
                            <div >
                                <div id='navDashContiner'>
                                    <Component initialState={{ isShown: false, nameُExpenses: '', amountExpenses: '', dateExpenses: '' }}>
                                        {({ state, setState }) => (
                                            <React.Fragment>
                                                <SideSheet
                                                    isShown={state.isShown}
                                                    position={Position.LEFT}
                                                    onCloseComplete={() => setState({ isShown: false })}
                                                >
                                                    <div margin={40}>
                                                        <div id='titleAddUser' style={{ backgroundColor: '#000' }}>اضافة قائمة جديدة </div>
                                                        <div id='inputAdd2Continer' >

                                                            <SelectField label="مندوب التوصيل"
                                                                id="SelectField"
                                                                onChange={(e) => {
                                                                    this.setState({ company: e.target.value })

                                                                }}>
                                                                <option value={"none"}>اختر المندوب</option>
                                                                {this.state.shiper.map((item) => (
                                                                    <option value={item.name}>{item.name}</option>
                                                                ))
                                                                }


                                                            </SelectField >
                                                        </div>
                                                        <div id='btnAddContiner'>
                                                            <div id='BtnAddUser' style={{ backgroundColor: '#000' }} onClick={() => {
                                                                console.log(this.state.company);

                                                                axios.post(host + `users/rejctedList/create/`,
                                                                    qs.stringify({
                                                                        name: this.state.company,

                                                                    }), {
                                                                    headers: {
                                                                        "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token")
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        console.log(response.data.id);

                                                                        window.location = `/list?id=${response.data.id}`;

                                                                        toast('تمت الاضافة بنجاح', {
                                                                            position: "bottom-center",
                                                                            autoClose: 5000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true
                                                                        });

                                                                    })
                                                                    .catch(error => {
                                                                        toast.error("حصل خطأ تأكد من المعلومات", {
                                                                            position: "bottom-center",
                                                                            autoClose: 5000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true
                                                                        });
                                                                        console.log(error)
                                                                        this.setState({ spinerBtn: false })
                                                                    })
                                                                setState({ isShown: false })

                                                            }}>اضافة</div>
                                                        </div>

                                                    </div>
                                                </SideSheet>
                                                <div id='BtnAddUser' style={{ backgroundColor: '#fff', color: '#000', height: 30, margin: 20 }} onClick={() => setState({ isShown: true })}>اضافة قائمة</div>
                                            </React.Fragment>
                                        )}
                                    </Component>

                                </div>


                                <div className='DataTableContiner'>
                                    <MuiThemeProvider
                                        theme={this.getMuiTheme()}>
                                        <MaterialDatatable data={this.state.orders} columns={columns} options={options} />
                                    </MuiThemeProvider>
                                </div>
                                <ToastContainer
                                    position="bottom-left"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                            </div>
                        )
                        // }
                        // else {
                        //     return (
                        //         <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        //             <Lottie
                        //                 options={{
                        //                     animationData: animation
                        //                 }}
                        //             />
                        //         </div>
                        //     )
                        // }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Category;