import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import { Row, Col, Card, Form, Image, Table, Accordion } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Component from "@reactions/component";
import { SideSheet, Position, Pane, Dialog, Button, Select } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import SearchIcon from '@material-ui/icons/Search';
import DeleteForeverIcon from '@material-ui/icons/Cancel';
import Clear from '@material-ui/icons/Clear';
import MultiSelect from "@kenshooui/react-multi-select";

import AddBusiness from '@material-ui/icons/DoneAll';


import Badge from 'react-bootstrap/Badge';
import Edit from '@material-ui/icons/Edit';
import ItemsImg from '../../assets/img/items.jpg';


// import Lottie from 'lottie-react-web';
// import animation from '../../assets/json/clean.json';
import 'react-toastify/dist/ReactToastify.css';
import MaterialDatatable from "material-datatable";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import qs from 'qs';
import * as moment from "moment-timezone";
import Login from '../common/login';
import exportFromJSON from 'export-from-json'
import CategoryIcon from '@material-ui/icons/Category';




// import { JsonToExcel } from "react-json-to-excel";


const columns = [


    { field: "text", name: "الغاء", options: { filter: true, sort: false, } },
    { field: "notes", name: " ملاحظات", options: { filter: true, sort: true, } },

    { field: "calls", name: "التبليغ", options: { filter: true, sort: true, } },

    { field: "calls_d", name: "تفاصيل التبليغ", options: { filter: true, sort: true, } },

    { field: "notes2", name: "المنتج", options: { filter: true, sort: true, } },
    { field: "safee", name: "الصافي", options: { filter: true, sort: true, } },



    //  { field: "items_count", name: "عدد القطع", options: { width: 20, filter: true, sort: true, } },
    //   { field: "safee", name: "الصافي", options: { width: 15, filter: true, sort: true, } },

    { field: "price", name: "السعر", options: { width: 5, filter: true, sort: true, } },
    { field: "government", name: "العنوان", options: { width: 15, filter: true, sort: true, } },
    { field: "phone", name: "رقم الهاتف", options: { width: 100, filter: true, sort: true, } },
    { field: "date", name: "تاريخ التسليم", options: { width: 15, filter: true, sort: true, } },

    { field: "id", name: "رقم الوصل", options: { width: 15, filter: true, sort: false, } },
    //   { field: "i", name: "#", options: { filter: true, sort: false, } },

];
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    print: false,
    sort: false,
    rowsPerPage: 30000,
    search: false,
    filter: false,
    viewColumns: true,
    download: false,
    pagination: false,
    onSearchChange: (e) => {
        console.log(e);
    },
    textLabels: {
        body: {
            noMatch: " لم يتم العثور على سجلات مطابقة",
            toolTip: "فرز",
        },
        pagination: {
            next: "الصفحة التالية",
            previous: "الصفحة السابقة",
            rowsPerPage: "عدد الصفوف",
            displayRows: "من",
        },
        toolbar: {
            search: "بحث",
            downloadCsv: "تنزيل",
            print: "Print",
            viewColumns: " التحكم بالاعمدة",
            filterTable: "فلتر",
        },
        filter: {
            all: "الكل",
            title: "فلتر",
            reset: "إعادة تعيين",
        },
        viewColumns: {
            title: "عرض الأعمدة",
            titleAria: "إظهار / إخفاء أعمدة الجدول",
        },

    }
}
const cookies = new Cookies();
let id = 0
class Selles_Reports extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: [],
            from: new Date(),
            to: new Date(),
            spin: false,
            serch: false,
            total: '',
            total_items: 0,
            total_orders: 0,
            items_cost: 0,
            orders_cost: 0,
            ordes2: [],
            items: [],
            id: '',
            cash: '',
            cash_orders: '',
            total_price: "",
            t_safee: 0,
            createdAt: '',
            selectedItems: [{}, {}],
        }
        const urlParams = new URLSearchParams(window.location.search);

        let Getid = urlParams.get('id')
        let type = urlParams.get('type')
        this.setState({ id: Getid, type })
        this.Orders(Getid)
        id = Getid
    }

    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'center',
                    fontSize: '25px',
                    fontWeight: 'bold',
                    border: '2px solid'

                },

            },
            MuiTableCell: {
                root: {
                    textAlign: 'right',

                },
                head: {
                    // backgroundColor: "#FF0000",
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: '25px',
                    border: '2px solid'
                }
            },

        }
    })

    onChangeD(value) {
        let count = document.getElementById(value).value;
        if (count > 0) {
            let newValue = Number(document.getElementById(value).value) - 1;
            document.getElementById(value).value = newValue
            // let re = ((item.count - count + 1) + 1)
            // console.log(re);
            // document.getElementById(text).textContent = `الكمية المتبقية ${re}`

        }

    }
    componentDidMount() {
        let header = { "Content-Type": "application/json", token: cookies.get("token") };
        axios.get(host + `dashbord/shiper/all/`, { headers: header })
            .then(res => {

                this.setState({ shiper: res.data.shiper })

            })
            .catch(error => {
                console.log(error.response)
            });

        const urlParams = new URLSearchParams(window.location.search);

        let Getid = urlParams.get('id')
        let type = urlParams.get('type')
        this.setState({ id: Getid, type })
        this.Orders(Getid)
        id = Getid
    }

    edit(obj, id) {

        let price = document.getElementById('Editprice').value;
        let notes = document.getElementById('Editnotes').value;

        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        let formData = new FormData();
        formData.append("price", price);
        formData.append("notes", notes);
        formData.append("is_edited", 1);
        formData.append("items", JSON.stringify(obj));
        axios({ url: host + `dashbord/order/edit/${id}`, method: "POST", data: formData, headers: header })
            .then(response => {
                this.componentDidMount();
                toast('تم التعديل بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            })
            .catch(error => { console.log(error.response.data.message) })

    }


    recjeted(data, price, id) {



        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        let formData = new FormData();

        formData.append("data", JSON.stringify(data));
        formData.append("price", Number(price));

        axios({ url: host + `dashbord/rejected/orders/update/${id}`, method: "post", data: formData, headers: header })
            .then(response => {
                this.componentDidMount();
                toast('تم تعديل الطلب', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                return true;
            })
            .catch(error => { console.log(error) })

    }
    Orders(id) {

        var header = { "Content-Type": "application/json", token: cookies.get("token") };
        const value = localStorage.getItem('rejcet_sort')
        let items_all = []
        axios.get(host + `dashbord/items/get/all`, { headers: header })
            .then((response) => {

                for (let index = 0; index < response.data.items.length; index++) {
                    let obj = {
                        label: response.data.items[index].name,
                        id: response.data.items[index].id,
                        count: response.data.items[index].count
                    }
                    items_all.push(obj)

                }

                //this.setState({ items: response.data.data })

            })
        let selectedItems = []
        axios.get(host + `users/list/${id}?sort=${value}`, { headers: header })

            .then(res => {
                if (res.data.list.is_send) {
                    document.getElementById('sendDiv').style.display = 'none'
                    
                    document.getElementById('seachBtn').disabled = true
                    document.getElementById('orderFiled').disabled = true
                }
                let arr = [];
                let items = res.data.list.orders

                let ordes2 = []
                let t_safee = 0;
                let total_price = 0
                for (let i = 0; i < items.length; i++) {
                    let obj = {
                        i: i + 1,
                        date: moment(items[i].outDate).format('DD/MM/YYYY'),
                        id: items[i].id,
                        phone: items[i].phone,
                        name: items[i].name,

                        hav_done: items[i].hav_done,
                        replacement: "",
                        calls_d: '',
                        government: items[i].government,
                        city: items[i].city,
                        price: items[i].price,
                        safee: items[i].price - items[i].shping_price,
                        notes2: items[i].notes2,
                        calls: <p style={{ color: "#dc0505" }}>{items[i].calls}</p>,
                        items_count: 1,
                        update: 0,
                        replacement: items[i].replacement,
                        notes: items[i].notes,
                        createdAt: items[i].createdAt,
                        hav: <Component initialState={{ isShown: false, price: "" }}>
                            {({ state, setState, }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        title='تعديل مواد الطلب'
                                        intent="warning"
                                        onCloseComplete={() => setState({ isShown: false })}
                                        confirmLabel="تعديل"
                                        cancelLabel="الغاء"
                                        onConfirm={() => {
                                            let upated_items = [];
                                            for (let index = 0; index < items[i].ordersFormItems.length; index++) {
                                                console.log(items[i].ordersFormItems[index].id);
                                                let obj = {
                                                    item_count: document.getElementById(`item${items[i].ordersFormItems[index].id}`).value,
                                                    item_id: items[i].ordersFormItems[index].components.id,
                                                    item_name: items[i].ordersFormItems[index].components.name,

                                                    id: items[i].ordersFormItems[index].id
                                                }
                                                upated_items.push(obj);





                                            }
                                            let new_price = document.getElementById('InputTExtDash111').value;
                                            if (!new_price) {
                                                document.getElementById('InputTExtDash111').style.border = "solid red"
                                                toast('تأكد من ادخال السعر الجديد', {
                                                    position: "bottom-center",
                                                    autoClose: 5000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: true
                                                });
                                                return -1
                                            }
                                            this.recjeted(upated_items, Number(new_price), items[i].id)
                                            // setState({ isShown: false })
                                            //    let check = this.recjeted(items[i].ordersFormItems, items[i].id)
                                            setState({ isShown: false })
                                        }}
                                    >
                                        <div>

                                            <Row >
                                                <Col >
                                                    <center>
                                                        <h5 style={{ margin: "20px" }}> المتنجات اللتي تم اختيارها في طلب رقم {items[i].id}</h5></center>
                                                    {items[i].ordersFormItems.map((item, i) => (
                                                        <Row className="justify-content-md-center" style={{ margin: '15px' }} key={i}>


                                                            <div>
                                                                <Card style={{ width: '300px' }}>

                                                                    <Card.Body>
                                                                        <center><b>{item.components.name}</b></center>
                                                                        <br></br>
                                                                        <Row >
                                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                                <Image style={{ width: '100px', height: '100px' }} src={ItemsImg} rounded />
                                                                                <div style={{ display: 'flex', marginLeft: '20px', marginBottom: '20px' }}>
                                                                                    <Button style={{ width: '50px', height: '50px' }} variant="danger" onClick={() => {

                                                                                        this.onChangeD(`item${item.id}`)
                                                                                    }}><b>-</b></Button>
                                                                                    <Form.Control style={{ width: '50px', height: '50px', fontWeight: 'bold', textAlign: 'center' }} value={item.count}
                                                                                        id={`item${item.id}`}
                                                                                        isabled />
                                                                                    <Button style={{ width: '50px', height: '50px' }} variant="success" disabled onClick={() => {
                                                                                        //   console.log(this.state.selectedItems);
                                                                                        // this.onChangeI(item.name, item, `${i}count`)

                                                                                    }}><b>+</b></Button>

                                                                                </div>

                                                                            </div>
                                                                            <center>
                                                                                <Form.Text
                                                                                // id={`${i}count`}
                                                                                >
                                                                                    {/* {`  الكمية المتبقية ${item.count}`} */}
                                                                                </Form.Text>
                                                                            </center>
                                                                        </Row>
                                                                        <hr></hr>
                                                                    </Card.Body>
                                                                </Card>
                                                            </div>




                                                        </Row>
                                                    ))}
                                                    <Row className="justify-content-md-center" style={{ margin: '15px' }}>
                                                        <div id={'ContinerInPut'} >

                                                            <input autoComplete='off' type='number' placeholder=' ادخل السعر ' id='InputTExtDash111'
                                                            />
                                                        </div>
                                                    </Row>
                                                </Col>
                                            </Row>



                                        </div>
                                    </Dialog>
                                    <Edit style={{ cursor: 'pointer', color: '#007bff' }}
                                        onClick={() => { setState({ isShown: true }) }} />
                                </Pane>
                            )}
                        </Component>,
                        items: <Component initialState={{ isShown: false }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        title="مواد الطلب"
                                        intent="success"
                                        hasFooter={false}
                                        onCloseComplete={() => setState({ isShown: false })}
                                        confirmLabel="انجاح"
                                        cancelLabel="الغاء"
                                        onConfirm={() => {
                                            setState({ isShown: false })
                                            //       this.deleverdCate(res.data.data.orders[i].id)
                                        }}
                                    >
                                        <div>
                                            <Table striped bordered hover>
                                                <thead>
                                                    <tr style={{ textAlign: 'right' }}>


                                                        <th >العدد</th>
                                                        <th>اسم المنتج</th>

                                                    </tr>
                                                </thead>
                                                <tbody>


                                                    {items[i].ordersFormItems.map((item, i) =>
                                                        <tr key={i} style={{ textAlign: 'right' }}>
                                                            <td>{item.count}</td>
                                                            <td>{item.components.name}</td>


                                                        </tr>
                                                    )}


                                                </tbody>
                                            </Table>
                                        </div>
                                    </Dialog>
                                    <CategoryIcon style={{ cursor: 'pointer', color: '#ffc107' }}
                                        onClick={() => { setState({ isShown: true }) }} />
                                </Pane>
                            )}
                        </Component>,
                        edit_order: <Component initialState={{ isShown: false, selectedItems, }}>
                            {({ state, setState }) => (
                                <React.Fragment>
                                    <SideSheet
                                        isShown={state.isShown}
                                        title="الغاء واعادة الطلب"
                                        intent="success"
                                        position={'left'}
                                        onCloseComplete={() => setState({ isShown: false })}
                                        confirmLabel="اعادة"
                                        cancelLabel="الغاء"
                                        onConfirm={() => {

                                        }}
                                    >

                                        <Pane flex="1" overflowY="scroll" background="tint1" padding={16}>
                                            <Card
                                                backgroundColor="white"
                                                elevation={0}
                                                height={150}
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <div>
                                                    <div>
                                                        <center>
                                                            <h2 style={{ margin: "20px" }}> اختر منتج</h2></center>
                                                        <MultiSelect
                                                            items={items_all}
                                                            selectedItems={state.selectedItems}
                                                            height={'20px'}
                                                            onChange={(e) => {

                                                                setState({ selectedItems: e })

                                                            }}
                                                            f
                                                            showSelectedItems={false}
                                                            showSelectAll={false}
                                                        />

                                                        <div>
                                                            <br></br>
                                                            <br></br>
                                                            <Table striped bordered hover style={state.selectedItems.length > 0 ? {} : { display: 'none' }}>
                                                                <thead>

                                                                    <tr>
                                                                        <th>العدد</th>
                                                                        <th>المنتج</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        state.selectedItems.map((item) => (
                                                                            <tr>
                                                                                <td>
                                                                                    <Component initialState={{ value: 1, }}>
                                                                                        {({ state, setState }) => (
                                                                                            <input type='number' id={item.id} style={{ width: "100 % ", border: "solid 1px" }}
                                                                                                value={state.value}
                                                                                                onChange={(e) => {
                                                                                                    console.log(e.target.value);
                                                                                                    setState({ value: e.target.value })

                                                                                                }} />
                                                                                        )}
                                                                                    </Component>

                                                                                </td>
                                                                                <td>{item.label}</td>

                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                            </Table>


                                                        </div>
                                                        <br></br>
                                                        <br></br>

                                                    </div>

                                                </div>

                                            </Card>
                                            <br></br>
                                            <Form.Group className="mb-3" >

                                                <Form.Control type="number" id="Editprice" placeholder="السعر مع التوصيل" />


                                            </Form.Group>
                                            <Form.Group className="mb-3" >
                                                <Form.Control as="textarea" id="Editnotes" placeholder="ملاحظات" rows={3} />
                                            </Form.Group>
                                            <center>
                                                <Button onClick={() => {

                                                    let arry = []
                                                    for (let index = 0; index < state.selectedItems.length; index++) {
                                                        let obj = {
                                                            id: state.selectedItems[index].id,
                                                            name: state.selectedItems[index].label,
                                                            count: Number(document.getElementById(state.selectedItems[index].id).value)
                                                        }

                                                        arry.push(obj)

                                                    }
                                                    this.edit(arry, items[i].id)

                                                }}> تعديل </Button>
                                            </center>


                                            <br></br>
                                        </Pane>
                                    </SideSheet >
                                    <Edit style={{ cursor: 'pointer', color: '#0c9eb5' }}
                                        onClick={() => { setState({ isShown: true }) }} />

                                </React.Fragment>
                            )}
                        </Component>,
                        text: <Component initialState={{ isShown: false }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        title={`الغاء مباشر`}
                                        intent="danger"
                                        onCloseComplete={() => setState({ isShown: false })}
                                        confirmLabel="تأكيد"
                                        cancelLabel="الغاء"
                                        onConfirm={() => {
                                            setState({ isShown: false })
                                            this.delete(items[i].id)
                                        }}
                                    >
                                        <span id='msgDelete'> هل انت متأكد تريد حذف  الطلب من القائمة  {items[i].id} ؟</span>
                                    </Dialog>
                                    <DeleteForeverIcon onClick={() => { setState({ isShown: true }) }} style={{ color: '#085119', fontSize: "30px", cursor: "pointer" }}></DeleteForeverIcon>

                                </Pane>
                            )}
                        </Component>,

                    };
                    if (res.data.list.is_send) {
                        obj.edit_order = "لا يمكن التعديل"
                        obj.text = "لا يمكن التعديل"
                    }
                    if (items[i].log.length > 0) {
                        let arr = []
                        for (let index = 0; index < items[i].log.length; index++) {
                            arr.push(<Badge bg="warning" text="dark">
                                {items[i].log[index].status}
                            </Badge>)

                        }
                        obj.calls_d = arr
                    }
                    if (items[i].replacement == true) {

                    }
                    var a = moment(moment(items[i].outDate));
                    var b = moment(res.data.list.createdAt);
                    // 1pi

                    if (b.diff(a, 'days') >= 14) {
                        obj.date = <p style={{ color: 'red' }}>{moment(items[i].outDate).format('DD/MM/YYYY')}</p>
                    } else {
                        obj.date = <p>{moment(items[i].outDate).format('DD/MM/YYYY')}</p>

                    }
                    t_safee = obj.safee + t_safee
                    let price = items[i].price - items[i].shping_price
                    let obj2 = {
                        'تاريخ الضمني': "   ",
                        'الحالة': "   ",
                        'رقم الزبون': items[i].phone,
                        'المبلغ الصافي': price,
                        'المبلغ التوصيل': items[i].shping_price,
                        'المبلغ الكلي': items[i].price,
                        'المحافظة': items[i].government,
                        'رقم الوصل': items[i].id,

                        'اسم البيج': 'items[i].page',
                        'التاريخ': moment(new Date()).format('DD/MM/'),











                    };
                    total_price = items[i].price + total_price
                    arr.push(obj);
                    ordes2.push(obj2)
                }


                this.setState({
                    orders_count: items.length,
                    orders: arr,
                    spin: false,
                    t_safee: t_safee,
                    total_price,
                    createdAt: moment(res.data.list.createdAt).format('DD/MM/YYYY'),
                    // total_items: res.data.data.items_sum,
                    // total_orders: res.data.data.order_count,
                    // items_cost: res.data.data.items_cost,
                    // orders_cost: res.data.data.sells,
                    ordes2,
                    // cash_orders: res.data.data.cash_orders,
                    // cash: res.data.data.cash,
                    // total_price: totalChild,
                    name: res.data.list.name
                });
            })
            .catch(error => {
                console.log(error.response)
            });
    }
    handleChangeFrom = date => {
        console.log(date);

        this.setState({
            from: date
        });
    };
    handleChangeTo = date => {
        this.setState({
            to: date
        });
    };
    download() {
        const data = this.state.ordes2
        const fileName = `${moment(new Date()).format('YYYY-MM-DD')}/${id}`
        const exportType = exportFromJSON.types.xls

        exportFromJSON({ data, fileName, exportType })
    }
    add(value) {

        axios.post(host + `users/list/add`,
            qs.stringify({
                list_id: id,
                order_id: value,

            }), {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token")
            }
        })
            .then(response => {
                this.Orders(id)
                document.getElementById('orderFiled').value = ''
                toast('تمت الاضافة بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });

            })
            .catch(error => {
                console.log(error.message);
                toast.error("حصل خطأ تأكد من المعلومات", {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                console.log(error)
                this.setState({ spinerBtn: false })
            })
    }

    delete(value) {

        axios.delete(host + `users/list/${value}`, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token")
            }
        })
            .then(response => {
                this.Orders(id)

                toast('ازلة الطلب من القائمة بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });

            })
            .catch(error => {

                toast.error("حصل خطأ تأكد من المعلومات", {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                console.log(error)

            })

    }
    send() {
        const urlParams = new URLSearchParams(window.location.search);
        let Getid = urlParams.get('id')


        axios.post(host + `dashbord/list/recjeted/send/${Getid}`, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token")
            }
        })
            .then(response => {
                this.Orders(id)

                toast('ازلة الطلب من القائمة بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });

            })
            .catch(error => {

                toast.error("حصل خطأ تأكد من المعلومات", {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                console.log(error)

            })


    }
    render() {
        if (cookies.get("token")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        // if (this.state.spin) {
                        return (
                            <div >

                                <div style={{ height: 100, justifyContent: "end" }} id='navDashContiner'>

                                    <div style={{ display: 'flex' }}>
                                        <Button id="seachBtn" style={{ height: "calc(1.5em + .75rem + 2px)", marginRight: "15px" }} appearance="primary" intent="success"
                                            iconBefore={SearchIcon}
                                            onClick={() => {
                                                let order_id = document.getElementById('orderFiled').value
                                                this.add(order_id)
                                            }}
                                            height={45}
                                        >
                                            بحث
                                        </Button>
                                        <Form.Control size="md" id={"orderFiled"} type="text" placeholder="رقم الوصل" onKeyPress={(e) => {

                                            if (e.key === "Enter") {
                                                if (e.target.value) {
                                                    this.add(document.getElementById('orderFiled').value)
                                                }
                                            }
                                        }} />

                                    </div>

                                </div>


                                <br />
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                                    <div style={{ textAlign: 'left', marginLeft: '15px' }}>
                                        <div id="sendDiv">
                                            <Component initialState={{ isShown: false, logding: false }}>
                                                {({ state, setState }) => (
                                                    <Pane>
                                                        <Dialog
                                                            isShown={state.isShown}
                                                            title={`ادخال مخزني`}
                                                            intent="danger"
                                                            isConfirmLoading={state.logding}
                                                            onCloseComplete={() => setState({ isShown: false })}
                                                            confirmLabel="تأكيد"
                                                            cancelLabel="الغاء"
                                                            onConfirm={() => {
                                                                // this.send()
                                                                setState({ logding: true })
                                                                const urlParams = new URLSearchParams(window.location.search);
                                                                let Getid = urlParams.get('id')

                                                                axios.post(host + `dashbord/list/recjeted/send/${Getid}`, {
                                                                    headers: {
                                                                        "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token")
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        this.Orders(id)
                                                                        setState({ isShown: false, logding: false })
                                                                        toast('تم ادخال القائمة الى المخزن بنجاح', {
                                                                            position: "bottom-center",
                                                                            autoClose: 5000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true
                                                                        });

                                                                    })
                                                                    .catch(error => {
                                                                        this.Orders(id)
                                                                        setState({ logding: false })
                                                                        toast.error("حصل خطأ تأكد من المعلومات", {
                                                                            position: "bottom-center",
                                                                            autoClose: 5000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true
                                                                        });
                                                                        console.log(error)

                                                                    })


                                                            }}
                                                        >
                                                            <span id='msgDelete'> هل متأكد ادخال القائمة الى المخزن ؟ </span>
                                                        </Dialog>
                                                        <Button appearance="primary" intent="success"
                                                            iconBefore={AddBusiness}
                                                            onClick={() => {
                                                                setState({ isShown: true })

                                                            }}
                                                            height={45}
                                                        >
                                                            ادخال الى المخزن
                                                        </Button>

                                                    </Pane>
                                                )}
                                            </Component>
                                        </div>





                                        <br />
                                    </div>
                                    <div style={{ textAlign: 'right', marginRight: '15px' }}>
                                        <h5>التاريخ: {this.state.createdAt}</h5>

                                        <h5>رقم القائمة: {id}</h5>
                                        <h5> اسم شركة التوصيل: {this.state.name}</h5>
                                        <h5>  عدد الطلبات :{this.state.orders_count} </h5>

                                        <h5> المبلغ الصافي  :{this.state.t_safee} </h5>
                                        <h5> المبلغ الكلي  :{this.state.total_price} </h5>

                                    </div>
                                </div>


                                <div>
                                    <div style={{ marginLeft: 25, marginBottom: 20 }}>
                                        <h4>ترتيب</h4>
                                        <Select onChange={(event) => {
                                            localStorage.setItem("rejcet_sort", event.target.value)
                                            window.location.reload();
                                        }}>
                                            <option value="non" selected>اختر ترتيب القائمة</option>
                                            <option value="last" >المضاف حديثآ</option>
                                            <option value="id">
                                                رقم الوصل
                                            </option>
                                        </Select>
                                    </div>



                                    <div className='DataTableContiner'>

                                        <Table striped bordered hover dir='RTL'>
                                            <thead>
                                                <tr>
                                                    <th>رقم الوصل</th>
                                                    <th>تاريخ التسليم</th>
                                                    <th> العنوان</th>
                                                    <th>السعر</th>
                                                    <th>الصافي</th>
                                                    <th>المنتج</th>
                                                    <th>التبليغ</th>
                                                    <th>ملاحظات</th>
                                                    <th>مواد الطلب</th>
                                                    <th>تعديل</th>
                                                    <th>الغاء</th>


                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.orders.map((order, i) =>
                                                    <tr style={order.hav_done == true ? { background: "#ffc107" } : order.replacement == true ? { background: "#89ffa4" } : {}}>
                                                        <td>{order.id}</td>
                                                        <td>{order.date}</td>
                                                        <td> {order.government}</td>
                                                        <td> {order.price}</td>
                                                        <td> {order.safee}</td>
                                                        <td> {order.notes2}</td>
                                                        <td> {order.calls}</td>
                                                        <td> {order.notes}</td>
                                                        <td>{order.items}</td>
                                                        <td>{order.edit_order}</td>
                                                        <td>{order.text}</td>
                                                    </tr>
                                                )}


                                            </tbody>
                                        </Table>
                                        {/* <MuiThemeProvider
                                            theme={this.getMuiTheme()}>
                                            <MaterialDatatable data={this.state.orders} columns={columns} options={options} />
                                        </MuiThemeProvider> */}
                                    </div>

                                </div>

                                <ToastContainer
                                    position="bottom-left"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                            </div>
                        )
                        // }
                        // else {
                        //     return (
                        //         <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        //             <Lottie
                        //                 options={{
                        //                     animationData: animation
                        //                 }}
                        //             />
                        //         </div>
                        //     )
                        // }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Selles_Reports;