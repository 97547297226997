import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';

import "react-datepicker/dist/react-datepicker.css";
import Component from "@reactions/component";
import { Pane, Dialog, Button, Label, Textarea, SelectField } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import LocalPrintshopIcon from '@material-ui/icons//AddShoppingCart';


import { Form, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// import { Form } from 'react-bootstrap';

// import Lottie from 'lottie-react-web';
// import animation from '../../assets/json/clean.json';
import 'react-toastify/dist/ReactToastify.css';
import { createMuiTheme } from '@material-ui/core/styles';

import * as moment from "moment-timezone";
import Login from '../common/login';
import exportFromJSON from 'export-from-json'
import DataTable from 'react-data-table-component';

const customStyles = {

    rows: {
        style: {
            minHeight: '72px', // override the row height
            border: 'solid 1px black'

        },
    },

    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            justifyContent: 'center',
            ' font-size': '25px',
            'font-weight': 'bold',
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
            border: 'solid 1px black',
            ' font-size': '20px',
            'font-weight': 'bold',
            justifyContent: 'center',
        },
    },
};
const conditionalRowStyles = [


    {
        when: row => row.seleced,
        style: {
            backgroundColor: "#28a745",
            userSelect: "none",
            color: 'white'
        }
    },

];

const columns = [
    {
        name: 'نوع الفحص',
        selector: row => row.type,
        sortable: true,
   

    },
    {
        name: 'الكلية',
        selector: row => row.dw_price + row.softCost + row.parts_costs,
        sortable: true,
    

    },
    {
        name: 'قطع الغيار',
        selector: row => row.parts_costs,
        sortable: true,


    },
    {
        name: 'السوف',
        selector: row => row.softCost,
        sortable: true,
       

    },
    {
        name: 'الشراء',
        selector: row => row.dw_price,
        sortable: true,
     

    },


    {
        name: 'رقم القائمة',
        selector: row => <Link to={`/SanaBill?id=${row.list_id}`}>{row.list_id}</Link>,
        sortable: true,
   
    },


    {
        name: 'النهائي',
        selector: row => row.item_out,
        sortable: true,
    },
    {
        name: ' الاولي',
        selector: row => row.item_in,
        sortable: true,
    },
    {
        name: 'موظف ',
        selector: row => row.name,
 

    },
    {
        name: ' رقم ',
        selector: row => row.id,
        sortable: true,

    },
];
let counts = []
const cookies = new Cookies();
let id = 0
class Selles_Reports extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: [],
            from: new Date(),
            to: new Date(),
            spin: false,
            serch: false,
            total: '',
            total_items: 0,
            total_orders: 0,
            items_cost: 0,
            orders_cost: 0,
            ordes2: [],
            shiper: [],
            id: '',
            ids: [],
            company: 'none',
            selectedData: [],
            price: 0
        }

        this.Orders()

    }
    rowSelect(data) {

        var Data = data.data;
        var row = data.rowsSelected;

        let ids = []
        for (let index = 0; index < row.length; index++) {
            let id = Data[index].data.id

            ids.push(id)
        }
        this.setState({ ids })
        // for (let index = 0; index < row.length; index++) {
        //     const element = array[index];

        // } 
        // this.setState({
        //     TableIndex: row,
        //     TableData: Data,
        //     button: true,
        // })
    }
    handleRowClicked(row) {

        let data = this.state.orders
        for (let index = 0; index < data.length; index++) {

            if (data[index].id === row.id) {
                data[index].seleced = !data[index].seleced
            }

        }

        this.setState({ orders: data })
    }




    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                // root: {
                //     //   backgroundColor: "#FF0000",
                //     textAlign: 'center',
                //     fontSize: '25px',
                //     fontWeight: 'bold',
                //     border: '2px solid'

                // },

            },

            MuiTableCell: {
                root: {
                    textAlign: 'right',

                },
                // head: {
                //     // backgroundColor: "#FF0000",
                //     textAlign: 'center',
                //     fontWeight: 'bold',
                //     fontSize: '25px',
                //     border: '2px solid'
                // }
            },

        }
    })

    Orders() {

        var header = { "Content-Type": "application/json", token: cookies.get("token") };


        axios.get(host + `users/sana/orders/ready`, { headers: header })
            .then(res => {
                let arr = [];
                let items = res.data.orders
                console.log(items);

                let ordes2 = []
                for (let i = 0; i < items.length; i++) {

                    items[i].seleced = false;
                    items[i].out = false;
                    arr.push(items[i]);

                }


                this.setState({
                    orders_count: items.length,
                    orders: items,

                });
            })
            .catch(error => {
                console.log(error.response)
            });
    }
    handleChangeFrom = date => {


        this.setState({
            from: date
        });
    };
    handleChangeTo = date => {
        this.setState({
            to: date
        });
    };
    download() {
        const data = this.state.ordes2
        const fileName = `${moment(new Date()).format('YYYY-MM-DD')}/${id}`
        const exportType = exportFromJSON.types.xls

        exportFromJSON({ data, fileName, exportType })
    }
    handleRowClicked = row => {


        let selectditems = this.state.selectedData;

        let check = this.state.selectedData.find((item) => item.id === row.id)
        if (check) {
            selectditems = this.state.selectedData.filter((item) => item.id !== row.id)

        } else {




            selectditems.push(row)
        }


        // toast(t_price, {
        //     position: "bottom-center",
        //     autoClose: 1500,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true
        // });

        const updatedData = this.state.orders.map(item => {
            if (row.id !== item.id) {
                return item;
            }

            return {
                ...item,
                seleced: !item.seleced,

            };
        });



        this.setState({ orders: updatedData, selectedData: selectditems })
        // setData(updatedData);
    };


    render() {
        if (cookies.get("token")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        // if (this.state.spin) {
                        return (
                            <div >




                                <br />
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                                    <div style={{ textAlign: 'left', marginRight: '20px', fontSize: "25px", fontWeight: "bold", color: '#007bff' }}>

                                    </div>
                                    <div style={{ textAlign: 'right', marginRight: '15px' }}>
                                        <Component initialState={{ isShown: false, items: [], notes: "", spin: true, check: 0, isConfirmLoading: false }}>
                                            {({ state, setState }) => (
                                                <Pane>
                                                    <Dialog
                                                        isShown={state.isShown}
                                                        isConfirmLoading={state.isConfirmLoading}
                                                        onCloseComplete={() => {
                                                            setState({ isShown: false, check: 0 })
                                                            this.setState({ company: 'none' })
                                                        }}
                                                        confirmLabel=" تحويل"

                                                        intent="success"
                                                        hasFooter={true}
                                                        title={"تحويلات الصيانة"}
                                                        onConfirm={() => {
                                                            setState({ isConfirmLoading: true })

                                                            let ids = []
                                                            for (let index = 0; index < this.state.selectedData.length; index++) {
                                                                ids.push(this.state.selectedData[index].id)

                                                            }
                                                            var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
                                                            let formData = new FormData();
                                                            formData.append("items", JSON.stringify(ids));


                                                            axios({ url: host + `users/sana/orders/toInv`, method: "post", data: formData, headers: header })
                                                                .then(response => {
                                                                    window.alert('تم تحويل الاجهزة ')
                                                                    window.location.reload()
                                                                })
                                                                .catch(error => { console.log(error.response.data.message) })



                                                        }}
                                                    >
                                                        <div style={{ direction: 'rtl', textAlign: 'center' }} >
                                                            <center>

                                                                <Alert variant={"info "}>
                                                                    هل فعلن عدد الاجهزة هو {this.state.selectedData.length} ؟
                                                                </Alert>
                                                            </center>


                                                        </div>
                                                    </Dialog>
                                                    <Button marginLeft={30} marginTop={5} disabled={this.state.selectedData.length > 0 ? false : true} appearance="primary"
                                                        iconBefore={LocalPrintshopIcon}
                                                        onClick={() => {


                                                            setState({ isShown: true, spin: true })
                                                        }}
                                                        height={45}
                                                    >
                                                        تحويل {this.state.selectedData.length} جهاز
                                                    </Button>
                                                </Pane>
                                            )}
                                        </Component>





                                        <br></br>
                                        <br></br>
                                        <br></br>
                                    </div>
                                </div>


                                <div>
                                    <div className='DataTableContiner'>
                                        <DataTable title="تحويلات الصيانة " highlightOnHover
                                            pointerOnHover direction={'center'}
                                            conditionalRowStyles={conditionalRowStyles}
                                            customStyles={customStyles}
                                            columns={columns}
                                            data={this.state.orders}
                                            //   selectableRows
                                            onRowClicked={this.handleRowClicked}

                                            onSelectedRowsChange={({ allSelected, selectedCount, selectedRows }) => {
                                                //   console.log(selectedRows);
                                                this.setState({ selectedData: selectedRows })


                                            }}
                                        //    selectableRowsComponent={Checkbox2}
                                        // onRowClicked={(row) => {

                                        //     this.handleRowClicked(row)
                                        // }}

                                        />

                                    </div>

                                </div>

                                <ToastContainer
                                    position="bottom-left"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                            </div>
                        )
                        // }
                        // else {
                        //     return (
                        //         <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        //             <Lottie
                        //                 options={{
                        //                     animationData: animation
                        //                 }}
                        //             />
                        //         </div>
                        //     )
                        // }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Selles_Reports;