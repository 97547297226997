import React from 'react';
import ScrollToTop from './assets/js/scrolToTop';
import Context from './assets/js/Context';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
// import NotFound from './assets/js/NotFound';
import SideBar from './component/common/sidebar';
import Cookies from "universal-cookie";
import host from './assets/js/Host';
import axios from 'axios';
import LoginDash from './component/common/login';
import Login from './component/web/login';
import getorderbyid from './component/dash/getOrderById';
import DoneTable from './component/dash/doneTable';
import Wasil from './component/dash/wasil';
import Safe from './component/dash/safe';

import Sana from './component/web/sana_main.jsx';
import sanaHome from './component/web/sana_home.jsx';
import SanaSoft from './component/web/sanaSoft.jsx';
import SanaCheck from './component/web/sanaCheck.jsx';

import ItemsSana from './component/dash/itemsSana.jsx';
import PckagingIphones from './component/dash/packagingIphones.jsx';


import sanaOrders from './component/web/sanaOrder.jsx';
import Appending from './component/web/appendingDev.jsx';

import Phone from './component/web/phone.jsx';
import SoftApD from './component/web/softApD.jsx';


import SanalistById from './component/dash/sanalistById.jsx';




import Devices from './component/dash/devices';
import Device from './component/dash/device';
import AdsReprots from './component/dash/ads_reports';
import List from './component/dash/listById';
import RejectListItems from './component/dash/rejectListItems';
import Schedule from './component/dash/Schedule';
import UserReprots from './component/dash/usersReports';
import Packaging from './component/dash/packaging';
import EditOrder from './component/dash/editOrder';

import callsUsers from './component/dash/calls';
import Items_lits from './component/dash/items_lits';

import SanaToInv from './component/dash/sanaToInv.jsx';


import Dayle_items from './component/dash/Dayle_items';
import Edit from './component/dash/edit';
import Dayle_orders from './component/dash/dayle_orders';
import CustomerOrder from './component/dash/customerOrder';
import Items2Dash from './component/dash/items2';
import Item2 from './component/web/item2'
import Side from './component/web/sidebar2';
import CustomerOrderUser from './component/web/customerOrder';

import Employee from './component/dash/employee';
import Mtaka from './component/dash/mtabka';


import Newbill from './component/dash/newBill';
import Bill from './component/dash/getBill';
import SupplierById from './component/dash/SupplierById';

import ShippingCompById from './component/dash/shippingCompById';

import SanaBill from './component/dash/sanaBill.jsx';

import AutoCamp from './component/dash/autoCamp.jsx';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/login.css';
import './assets/css/sidebar.css';
import './App.css';
const cookies = new Cookies();

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      shopNum: '',
    }
  }
  componentDidMount() {

    if (cookies.get('token')) {
      if (window.location.pathname != "/") {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.get(host + `dashbord/profile/`, { headers: header })
          .then(res => {

          })
          .catch(error => {
            window.location.href = "/";

          })
      }

    }

  }
  render() {
    return (
      <div >
        <Context.Provider value={{
          value: this.state, action: {
            RefreshComponent: () => {
              this.componentDidMount()
            },
          }
        }}>
          <div className="App">
            <BrowserRouter>
              <ScrollToTop>
                <Switch>
                  <Route exact path='/' component={LoginDash} />
                  <Route path='/loginDash' component={LoginDash} />
                  <Route path='/Expenses' component={SideBar} />
                  <Route path='/fromorders' component={SideBar} />
                  <Route path='/Search' component={SideBar} />
                  <Route path='/fromordersReports' component={SideBar} />
                  <Route path='/fromordersWatting' component={SideBar} />
                  <Route path='/getorderbyid' component={getorderbyid} />
                  <Route path='/Report' component={SideBar} />
                  <Route path='/delevryReports' component={SideBar} />
                  <Route path='/m3alk' component={SideBar} />
                  <Route path='/SettingDash' component={SideBar} />
                  <Route path='/Cate2' component={SideBar} />
                  <Route path='/DoneOrder' component={SideBar} />
                  <Route path='/RejectOrder' component={SideBar} />
                  <Route path='/selles' component={SideBar} />
                  <Route path='/doneselles' component={SideBar} />
                  <Route path='/wattingselles' component={SideBar} />
                  <Route path='/apadningOrders' component={SideBar} />
                  <Route path='/cancelledOrders' component={SideBar} />
                  <Route path='/calledOrders' component={SideBar} />



                  <Route path='/printlist' component={SideBar} />
                  <Route path='/dayle_orders' component={Dayle_orders} />
                  <Route path='/dayle_items' component={Dayle_items} />

                  <Route path='/sheet' component={SideBar} />
                  <Route path='/rejctedList' component={SideBar} />
                  <Route path='/miss' component={SideBar} />
                  <Route path='/check' component={SideBar} />
                  <Route path='/print' component={SideBar} />
                  <Route path='/print' component={SideBar} />

                  <Route path='/billsList' component={SideBar} />
                  <Route path='/suppliersList' component={SideBar} />

                  <Route path='/shippingList' component={SideBar} />
                  <Route path='/replacement' component={SideBar} />

                  <Route path='/logs' component={SideBar} />
                  <Route path='/logscan' component={SideBar} />

                  <Route path='/shippingcompaniesList' component={SideBar} />

                  <Route path='/wasil' component={Wasil} />
                  <Route path='/rejectListItems' component={RejectListItems} />

                  <Route path='/list' component={List} />

                  <Route path='/Category2' component={Side} />
                  <Route path='/Shop2' component={Side} />
                  <Route path='/AllProdect' component={Side} />
                  <Route path='/EmployeeList' component={SideBar} />
                  <Route path='/calsslog' component={SideBar} />
                  <Route path='/Orders' component={Side} />
                  <Route path='/Customers' component={Side} />
                  <Route path='/CustomerOrder/:id' component={CustomerOrderUser} />
                  <Route path='/Edit/:id' component={Edit} />
                  <Route path='/Item2/:id' component={Item2} />
                  <Route path='/devices' component={Devices} />
                  <Route path='/device' component={Device} />
                  <Route path='/CustomerOrderDash/:id' component={CustomerOrder} />
                  <Route path='/Items2Dash/:id' component={Items2Dash} />
                  <Route path='/ItemsSana/:id' component={ItemsSana} />
                  <Route path='/se' component={Items2Dash} />
                  <Route path='/Schedule' component={Schedule} />
                  <Route path='/Employee' component={Employee} />
                  <Route path='/editOrder' component={EditOrder} />
                  <Route path='/packaging' component={PckagingIphones} />
                  <Route path='/packaging2' component={PckagingIphones} />
                  <Route path='/AdsReprots' component={AdsReprots} />
                  <Route path='/newbill' component={Newbill} />
                  <Route path='/Bill' component={Bill} />
                  <Route path='/doneTable' component={DoneTable} />
                  <Route path='/userReprots' component={UserReprots} />
                  <Route path='/callsUsers' component={callsUsers} />
                  <Route path='/litsItems' component={Items_lits} />
                  <Route path='/Supplier' component={SupplierById} />
                  <Route path='/Shipping' component={ShippingCompById} />
                  <Route path='/mtabka' component={Mtaka} />
                  <Route path='/safe' component={SideBar} />
                  <Route path='/uncheckedBill' component={SideBar} />


                  <Route path='/sanaLists' component={SideBar} />
                  <Route path='/sanalistById' component={SanalistById} />


                  <Route path='/sanaDevs' component={SideBar} />

                  

                  <Route path='/unavailable' component={SideBar} />
                  <Route path='/Sana' component={Sana} />
                  <Route path='/phone_S' component={Phone} />


                  <Route path='/s_home' component={sanaHome} />
                  <Route path='/sanaSoft' component={SanaSoft} />

                  <Route path='/softApD' component={SoftApD} />


                  <Route path='/SanaBill' component={SanaBill} />


                  
                  <Route path='/sanaCheck' component={SanaCheck} />

                  <Route path='/sanaOrders' component={sanaOrders} />
                  <Route path='/autoCamp' component={AutoCamp} />
                  <Route path='/appending' component={Appending} />
                  <Route path='/sanaToInv' component={SideBar} />

                  {/* <Route  exact={true} path='*' component={NotFound} /> */}
                </Switch>
              </ScrollToTop>
            </BrowserRouter>
          </div>
        </Context.Provider>

      </div>
    );
  }
}

export default App;
