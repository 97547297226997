import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import qs from 'qs';
import Component from "@reactions/component";
import { Spinner, Icon, Badge, Pane } from 'evergreen-ui';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Lottie from 'lottie-react-web';
// import animation from '../../assets/json/clean.json';
import { Row, Col, Container, Form, Button, Card, ListGroup, } from 'react-bootstrap';
import Login from './login';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { MultiSelect } from "react-multi-select-component";
import Multiselect from 'multiselect-react-dropdown';
import { MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import Select from 'react-select'
import MobM from '../../assets/img/mobM.png'
import MB2 from '../../assets/img/mb2.webp'

import moment from 'moment';
const options = [
    { label: "Grapes 🍇", value: "grapes", isDisabled: false },
    { label: "Mango 🥭", value: "mango", isDisabled: true },
    { label: "Strawberry 🍓", value: "strawberry", isDisabled: true },
];

const cookies = new Cookies();

const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    console.log(string, results)
}

const handleOnHover = (result) => {
    // the item hovered
    console.log(result)
}


const formatResult = (item) => {
    return (
        <>
            {/* <span style={{ display: 'block', textAlign: 'left' }}>id: {item.id}</span> */}
            <span style={{ display: 'block', textAlign: 'left' }}> {item.name}</span>
        </>
    )
}

const handleOnFocus = () => {
    console.log('Focused')
}

class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: "",
            outItems: [],
            spin: false,
            options: [],
            optionsSelcted: [],
            itemIn: "",
            itemOut: "",

            list_id: "",
            type: 0,
            list_date: "",
            battery: "",
            user: "",
            parts: [],
            fields: { value: 'name', disabled: 'disabled' }


        }

    }

    componentDidMount() {


        const urlParams = new URLSearchParams(window.location.search);

        let Getid = urlParams.get('id')
        var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("tokenUser") };
        axios.get(host + `users/sana/orders/phone/${Getid}`, { headers: header })
            .then(res => {
                // console.log(res.data.data.items);
                let out = "لم يتم التحديد"
                if (res.data.phone.item_out) {
                    out = res.data.phone.item_out
                }
                let type = "صيانة"
                console.log(res.data.phone.type);

                if (res.data.phone.type == 1) {
                    type = "رواجع"
                }
                this.setState({
                    items: res.data.phone,
                    itemIn: res.data.phone.item_in,
                    createdAt: res.data.phone.createdAt,
                    itemOut: out,
                    type,
                    parts: res.data.phone.order,
                    parts: res.data.phone.order,
                    parts: res.data.phone.order,
                    list_id: res.data.phone.list.id,
                    list_date: res.data.phone.list.updatedAt,
                    battery: res.data.phone.battery,
                    user: res.data.phone.admin.name,
                    spin: true
                });
            })
            .catch(error => { console.log(error.response) })





    }


    render() {
        if (cookies.get("tokenUser")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        // if (this.state.spin) {
                        return (
                            <div id='HomeContiner'>
                                <div id='navDashContiner'>
                                    <Link to='/s_home'>
                                        <Icon icon="circle-arrow-left" size={30} color="#fff"
                                            style={{ cursor: 'pointer' }} />
                                    </Link>
                                </div>
                                <div id='HomeContiner1'>
                                    <br></br>
                                    <br></br>
                                    <Container>
                                        <Row className="justify-content-md-center">
                                            <Col md={{ offset: 4 }}>
                                                <Card dir='rtl' style={{ width: '22rem' }}>
                                                    <Card.Header></Card.Header>
                                                    <ListGroup variant="flush">
                                                        <ListGroup.Item style={{ textAlign: "right" }}>رقم الجهاز :  {this.state.items.id}  </ListGroup.Item>

                                                        <ListGroup.Item style={{ textAlign: "right" }}>رقم القائمة :  {this.state.list_id}  </ListGroup.Item>
                                                        <ListGroup.Item style={{ textAlign: "right" }}>تاريخ القائمة :  {moment(this.state.list_date).format("DD-MM-YYYY")}  </ListGroup.Item>
                                                        <ListGroup.Item style={{ textAlign: "right" }}>تاريخ الفحص :  {moment(this.state.createdAt).format("DD-MM-YYYY")}  </ListGroup.Item>

                                                        <ListGroup.Item style={{ textAlign: "right" }}>معرف الجهاز :  {this.state.items.uuid}  </ListGroup.Item>
                                                        <ListGroup.Item style={{ textAlign: "right" }}>نوع الجهاز الأولي  :  {this.state.itemIn.name}  </ListGroup.Item>
                                                        <ListGroup.Item style={{ textAlign: "right" }}>نوع الجهاز مع الذاكرة  :  {this.state.itemOut.name}  </ListGroup.Item>

                                                        <ListGroup.Item style={{ textAlign: "right" }}> نسبة البطارية  :  {this.state.battery}  </ListGroup.Item>
                                                        <ListGroup.Item style={{ textAlign: "right" }}>  الموظف  :  {this.state.user}  </ListGroup.Item>
                                                        <ListGroup.Item style={{ textAlign: "right" }}>نوع   التحويل  :  <Badge style={{ margin: "5px", fontSize: "15px" }} color="green" marginRight={8}>


                                                            {this.state.items.status}
                                                        </Badge>    </ListGroup.Item>

                                                        <ListGroup.Item style={{ textAlign: "right" }}

                                                        >نوع  الفحص   :
                                                            <Badge style={{ margin: "5px", fontSize: "15px" }} color="orange" marginRight={8}>
                                                                {this.state.type}
                                                            </Badge>

                                                        </ListGroup.Item>
                                                        <ListGroup.Item style={{ textAlign: "right" }}>  الملاحضات  :  {this.state.items.notes}   </ListGroup.Item>

                                                        <ListGroup.Item style={{ textAlign: "right" }}>   قطع الغيار المستخدمة  :    </ListGroup.Item>



                                                    </ListGroup>
                                                    <Card.Body>
                                                        <Card.Text>
                                                            <center>
                                                                {this.state.parts.map((order, index) => (

                                                                    <Badge style={{ margin: "5px", fontSize: "15px" }} color="teal">{order.part.name}</Badge>
                                                                ))}

                                                            </center>



                                                        </Card.Text>

                                                    </Card.Body>

                                                    <Button onClick={() => {
                                                        window.location.href = `/Sana`;
                                                    }} variant="primary">اضافة جهاز جديد </Button>

                                                </Card>
                                            </Col>
                                        </Row>

                                    </Container>

                                </div>
                                <ToastContainer
                                    position="bottom-left"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                            </div>
                        )
                        // }
                        // else {
                        //     return (
                        //         <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        //             <Lottie
                        //                 options={{
                        //                     animationData: animation
                        //                 }}
                        //             />
                        //         </div>
                        //     )
                        // }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Category;